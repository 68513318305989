:root {
  --primary-color: #dc143c;
  --primary-hover: #cc0a31;
  --secondary-color: #3b3333;
  --text-color: #6b6b6b;
  --background-color: #f0f0f0;
  --star-color: #eea929;
  --red-color: #ee2929;
  --green-color: #00e908;
}

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

body {
  background-color: var(--background-color);
}

.page_container {
  width: 100%;
  padding-top: 60px;
}

a {
  text-decoration: none;
  color: #000;
}

.success {
  text-align: center;
  color: var(--green-color);
  padding: 8px 0;
}

.error {
  text-align: center;
  color: var(--red-color);
  padding: 8px 0;
}

.heading {
  text-align: center;
  text-transform: capitalize;
  font-family: 'Lucida Sans', 'Lucida Sans Regular', 'Lucida Grande',
    'Lucida Sans Unicode', Geneva, Verdana, sans-serif;
  font-size: 1.3rem;
  padding-top: 3rem;
  color: var(--primary-color);
}

.main-banner {
  position: relative;
  width: 100%;
  height: 100vh;
}

.banner-img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.main-banner-text {
  width: 100%;
  position: absolute;
  top: 40%;
  left: 50%;
  transform: translate(-50%, -50%);
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 2rem;
  color: #fff;
}

.main-banner-text .heading {
  padding-top: 0;
  color: #fff;
  font-size: 1.2rem;
}

.search-form {
  width: 95%;
  display: flex;
  justify-content: center;
}

.defaultOption {
  color: #0e0e0e;
}

.search {
  width: 100%;
  max-width: 350px;
  padding: 10px;
  border: none;
  outline: none;
  border-radius: 0;
  background-color: #fff;
}

.search-btn {
  height: 50px;
  font-size: 0.9rem;
  font-weight: 600;
  padding: 0 2rem;
  border: none;
  border-radius: 10px;
  text-transform: uppercase;
  color: #fff;
  background-color: var(--primary-color);
}

.search-btn:hover {
  cursor: pointer;
  box-shadow: 2.5px 2.5px 0 0 var(--primary-hover);
  transform: translate(-2.5px, -2.5px);
}

.main-container {
  display: grid;
  justify-items: center;
  grid-template-columns: repeat(1, 1fr);
  position: relative;
  margin-top: 2rem;
  padding-bottom: 6.7rem;
  gap: 1.5rem;
}

.car-container {
  width: 90%;
  height: 290px;
  background-color: #fff;
  box-shadow: 0 0 30px 0 #bbbbbb4b;
  display: flex;
  flex-direction: column;
  align-items: center;
  border-radius: 10px;
}

.image {
  width: 100%;
  height: 165px;
  margin-top: 5px;
}

.car-image {
  width: 100%;
  height: 100%;
  object-fit: contain;
}

.car-details {
  width: 100%;
  display: flex;
  justify-content: space-between;
  padding: 0 1rem;
  align-items: center;
}

.right {
  font-size: 1rem;
  text-align: right;
  line-height: 1.1;
}

.btn {
  font-size: 0.9rem;
  font-weight: 700;
  padding: 10px 25px;
  text-transform: uppercase;
  border: none;
  border-radius: 10px;
  color: #fff !important;
  background-color: var(--primary-color);
}

.btn:hover {
  cursor: pointer;
  box-shadow: 2.5px 2.5px 0 0 var(--primary-hover);
  transform: translate(-2.5px, -2.5px);
}

.arrow-btn {
  position: absolute;
  top: 96%;
  transform: translateY(-50%);
  height: 80px;
  width: 80px;
  color: #fff;
  background-color: var(--primary-color);
  border: none;
  font-size: 2rem;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
}

.prev-btn {
  left: 0;
  border-top-right-radius: 50%;
  border-bottom-right-radius: 50%;
}

.prev-btn:hover .arrow {
  transform: translateX(-4px);
}

.next-btn {
  right: 0;
  border-bottom-left-radius: 50%;
  border-top-left-radius: 50%;
}

.next-btn:hover .arrow {
  transform: translateX(4px);
}

.disabled {
  mix-blend-mode: multiply;
  background-color: #a8a8a8;
}

.star {
  font-size: 20px;
  color: var(--star-color);
}

.delete-icon {
  font-size: 1rem;
  margin-right: 2.5px;
  transform: translateY(3px);
}

.back-to-main-link {
  position: absolute;
  left: 25px;
  top: 13.5px;
  font-size: 1.4rem;
}

.filter-container {
  display: flex;
  flex-direction: column;
  background-color: #fff;
  align-items: center;
  gap: 0.5rem;
  padding: 0.5rem 0.2rem;
  border-radius: 10px;
  width: 95%;
}

.filter-select {
  width: 100%;
  height: 50px;
  box-shadow: none;
  border: none;
  border-right: 1px solid #ced6e0;
  transition: all 0.3s ease-in-out;
  font-size: 18px;
  padding: 5px 15px;
  background: #fff;
  color: #1a3b5d;
  font-family: 'Source Sans Pro', sans-serif;
}

.filter-input {
  width: 100%;
  box-shadow: none;
  border: none;
  border-right: 1px solid #ced6e0;
  transition: all 0.3s ease-in-out;
  font-size: 18px;
  padding: 5px 15px;
  background: none;
  color: #1a3b5d;
  font-family: 'Source Sans Pro', sans-serif;
}

.filter-input {
  height: 50px;
}

.create-car {
  display: flex;
  justify-content: center;
  padding: 2rem 2rem 0 2rem;
}

@media (min-width: 425px) {
  .main-banner-text .heading {
    font-size: 1.3rem;
  }

  .car-container {
    width: 85%;
  }
  .create-car .button {
    width: 100%;
  }
}

@media (min-width: 480px) {
  .main-banner-text .heading {
    font-size: 1.4rem;
  }
  .car-container {
    width: 80%;
  }
}

@media (min-width: 768px) {
  .heading {
    font-size: 1.7rem;
  }

  .main-container {
    grid-template-columns: repeat(3, 1fr);
    gap: 2rem;
    padding: 0 2rem 6.7rem 2rem;
  }

  .car-container {
    width: 100%;
  }

  .arrow-btn {
    top: 92.5%;
  }

  .create-car {
    justify-content: left;
  }

  .create-car .button {
    width: auto;
  }
}

@media (min-width: 1024px) {
  .filter-container {
    flex-direction: row;
  }

  .main-banner-text .heading {
    font-size: 1.7rem;
  }

  .main-container {
    padding: 2rem 5.5rem;
    margin-top: 0;
  }

  .create-car {
    padding: 2rem 5.5rem;
  }

  .banner-img {
    object-position: top;
  }

  .main-banner-text {
    top: 35%;
    gap: 20px;
  }

  .search {
    max-width: 400px;
  }

  .arrow-btn {
    top: 50%;
  }
}

@media (min-width: 1200px) {
  .main-container {
    padding: 2rem 8rem;
    gap: 2.5rem;
  }

  .create-car {
    padding: 2rem 8rem;
  }

  .car-container {
    height: 315px;
  }

  .car-details {
    padding: 0 3rem;
  }
}

@media (min-width: 1400px) {
  .main-container {
    padding: 2rem 10rem;
    gap: 3rem;
  }

  .create-car {
    padding: 2rem 10rem;
  }

  .banner-img {
    object-position: center;
  }

  .car-container {
    height: 345px;
  }

  .car-details {
    padding: 0 5rem;
    padding-top: 1rem;
  }

  .btn {
    padding: 12px 30px;
    font-size: 1rem;
  }
}
