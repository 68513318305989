.profile-container {
  width: 100%;
  max-width: 300px;
  display: flex;
  flex-direction: column;
  gap: 10px;
  padding: 20px;
  margin: 50px auto;
  background-color: #fff;
}

.profile-img {
  width: 260px;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 10px;
  margin-bottom: 30px;
  position: relative;
}

.profile-img img {
  width: 200px;
  height: 200px;
  border-radius: 50%;
  object-fit: cover;
}

.edit-btn {
  position: absolute;
  top: 180px;
  right: 30px;
  border: none;
  background: transparent;
  font-size: 1.1rem;
}

.edit-btn:hover {
  cursor: pointer;
  color: var(--text-color);
}

.profile {
  display: flex;
  flex-direction: column;
}

.para {
  display: flex;
  justify-content: flex-start;
  gap: 5px;
  padding-left: 10px;
  color: var(--secondary-color);
}

.para span:first-child {
  font-weight: 600;
  color: var(--primary-color);
}

.para button {
  border: none;
  background: transparent;
  font-size: 0.9rem;
  transform: translateY(3px);
}

.email {
  margin-left: 34px;
}

.user-form {
  text-align: center;
  margin-top: 3px;
}

.user-form label {
  font-size: 0.85rem;
  line-height: 2;
  color: var(--green-color);
}

.user-form input {
  width: 70%;
  height: 30px;
  padding: 5px;
  font-size: 0.8rem;
  outline: none;
  border: 1px solid var(--primary-color);
  border-right: none;
}

.user-form button {
  width: 60px;
  height: 30px;
  font-weight: 600;
  color: #fff;
  border: 1px solid var(--primary-color);
  background-color: var(--primary-color);
}

.para button:hover {
  cursor: pointer;
  color: var(--text-color);
}

.user-form button:hover {
  cursor: pointer;
  box-shadow: 2.5px 2.5px 0 0 var(--primary-hover);
  transform: translate(-2.5px, -2.5px);
}

@media (min-width: 992px) {
  .profile-container {
    max-width: 400px;
  }

  .profile-img {
    margin: 30px auto;
  }

  .para {
    padding-left: 40px;
    font-size: 1.1rem;
    gap: 10px;
  }

  .email {
    margin-left: 37px;
  }

  .user-form input {
    width: 200px;
  }
}
