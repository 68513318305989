.navbar {
  position: fixed;
  top: 0;
  width: 100%;
  height: 80px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: var(--primary-color);
  z-index: 11;
}

.nav {
  display: flex;
  align-items: stretch;
  justify-content: flex-start;
}

.mainTitle {
  font-size: 1.625rem;
  line-height: 40px;
}

.hamburger_menu {
  width: fit-content;
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 5px;
  z-index: 100;
  top: 0;
  left: 0;
  padding: 0 0.6rem;
}

.hamburger_menu:hover {
  cursor: pointer;
}

.line {
  width: 30px;
  height: 5px;
  background-color: #fff;
  -webkit-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
}

.hamburger_menu.active .line:nth-child(1) {
  transform: translateY(10px) rotate(45deg);
  z-index: 100;
}

.hamburger_menu.active .line:nth-child(2) {
  opacity: 0;
}

.hamburger_menu.active .line:nth-child(3) {
  transform: translateY(-10px) rotate(-45deg);
  z-index: 100;
}

.nav-logo-container {
  display: flex;
  align-items: center;
  gap: 1rem;
  color: white;
}

.nav-logo {
  width: 60px;
}

.nav-logo.active {
  display: none;
}

.nav-logo-img {
  width: 100%;
  height: 100%;
  object-fit: contain;
}

.nav-links {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-right: 0.6rem;
  gap: 0.5rem;
}

.ver-line {
  width: 1.5px;
  height: 23px;
  background-color: #fff;
}

.nav-link {
  color: #fff !important;
  font-family: 'Lucida Sans', 'Lucida Sans Regular', 'Lucida Grande',
    'Lucida Sans Unicode', Geneva, Verdana, sans-serif;
  font-weight: 700;
  text-transform: uppercase;
  position: relative;
  font-size: 0.9rem;
  cursor: pointer;
}

.nav-link::after {
  content: '';
  position: absolute;
  width: 0;
  height: 1px;
  display: inline-block;
  left: 0;
  bottom: 0;
  background-color: #fff;
  transition: 0.3s;
}

.nav-link:hover::after {
  width: 100%;
}

ul li {
  list-style: none;
}

.nav_menu_container {
  position: fixed;
  top: 0;
  left: -100%;
  z-index: 10;
  transition: 0.3s;
}

.nav_menu_container.active {
  left: 0;
}

.nav_menu {
  position: relative;
  width: 220px;
  height: 100vh;
  background-color: var(--primary-color);
  display: flex;
  flex-direction: column;
  text-align: start;
  padding: 70px 0;
}

.logo {
  width: 140px;
  margin: 0 auto 25px auto;
}

.logo-img {
  width: 100%;
}

.menu_links_wrapper {
  position: relative;
  height: 100vh;
  display: flex;
  flex-direction: column;
  padding-left: 20px;
  color: var(--secondary-color);
}

.menu_link {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  width: 100%;
  height: 50px;
  border-top-left-radius: 5px;
  border-bottom-left-radius: 5px;
  color: #fff !important;
  font-size: 0.9rem;
}

.activeLink {
  background-color: var(--primary-hover);
  box-shadow: inset 0 0.5px 0 0.5px #ae0829;
}

.menu_link:hover {
  background-color: var(--primary-hover);
}

.nav-icon {
  font-size: 1.3rem;
  margin: 0 10px;
}

.nav-line {
  width: 90%;
  height: 1px;
  margin: 8px 0;
  background-color: #fff;
}

.nav-footer {
  color: #fff;
  font-size: 0.85rem;
  line-height: 1.5;
  margin-left: 10px;
}

.nav-info {
  margin: 10px;
  display: flex;
  justify-content: flex-start;
  gap: 15px;
}

.nav-info-icon {
  font-size: 0.9rem;
  color: #ccc;
}

.nav-info-icon:hover {
  color: #fff;
}

.user-info {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 57px;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 10px;
  font-size: 0.9rem;
  color: #fff;
  background-color: var(--primary-hover);
}

.user-profile {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 10px;
  color: #fff;
}

.user-img {
  width: 40px;
  height: 40px;
  object-fit: cover;
  border-radius: 50%;
}

.user-email {
  font-size: 0.85rem;
  color: #ddd;
}

.signout-icon {
  font-size: 1.5rem;
  color: #fff;
  transform: translateY(2px);
}

.user-info:hover {
  background-color: var(--red-color);
}

@media screen and (min-width: 425px) {
  .hamburger_menu {
    padding: 0 1rem;
  }

  .nav-links {
    margin-right: 1rem;
    gap: 0.7rem;
  }

  .nav-link {
    font-size: 0.95rem;
  }
}

@media screen and (min-width: 480px) {
  /*  .nav-logo {
    width: 170px;
  } */

  .nav-link {
    font-size: 1rem;
  }
}

@media screen and (min-width: 768px) {
  .nav_menu {
    width: 210px;
  }

  .mainTitle {
    font-size: 2.25rem;
    line-height: 50px;
  }
}

@media screen and (min-width: 1200px) {
  .nav-logo {
    margin-left: 10px;
  }

  .nav-links {
    gap: 1.5rem;
  }

  .ver-line {
    width: 1.8px;
    height: 27px;
  }

  .nav-link {
    font-size: 1.1rem;
  }

  .menu_link {
    font-size: 1rem;
  }

  .nav-footer {
    margin-top: 10px;
    line-height: 1.8;
  }

  .nav-info {
    margin-top: 15px;
  }

  .nav-info-icon {
    font-size: 1rem;
  }
}

@media screen and (min-width: 1400px) {
  .logo {
    width: 180px;
  }

  .nav_menu {
    width: 230px;
  }

  .user-info {
    gap: 14px;
  }
}
