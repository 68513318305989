#detail {
  position: relative;
  height: 100vh;
}

.header {
  width: 100%;
  height: 60px;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: var(--primary-color);
}

.header-title {
  text-align: center;
  margin: 0;
  padding: 19.2px 0;
  font-size: 2rem;
  font-weight: 600;
  color: #fff;
}

.section-title {
  font-size: 2rem;
  font-weight: 600;
  text-align: center;
}

.back-btn {
  position: absolute;
  left: 10px;
  top: 16px;
  font-size: 1.8rem;
  color: #fff;
}

.back-btn:hover {
  cursor: pointer;
}

.detail-container {
  display: flex;
  flex-direction: column;
  width: 100%;
  justify-content: center;
  gap: 2.5rem;
}

.detail-wrapper {
  padding: 0 1rem;
  width: 100%;
}

.details {
  /*width: 88%;*/
  /* margin: 35px auto;*/
  padding-bottom: 35px;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 30px;
}

.banner {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 1rem 0;
  padding-top: 2rem;
  padding-left: 1rem;
  padding-right: 1rem;
  gap: 0.5rem;
  /* flex: 1; */
}

.banner-text {
  display: none;
}

.img-container {
  height: auto;
  margin: 0 auto;
}

.img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 10px;
}

.img-text {
  width: 100%;
  display: flex;
  align-items: center;
  font-weight: 500;
  color: var(--secondary-color);
}

.rating {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 5px;
}

.user-icon {
  font-size: 2.5rem;
  padding: 8px;
  color: var(--star-color);
  background-color: #fff;
  border: 2px solid var(--star-color);
  border-radius: 50%;
}

.rating p {
  margin: 3px 0;
}

.star {
  font-size: 1rem;
}

.fill {
  color: var(--star-color);
}

.price {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  font-size: 0.8rem;
}

.price p {
  margin: 0;
  line-height: 1.1;
}

.price p:first-child {
  font-size: 1.2rem;
}

.features {
  width: 100%;
  padding: 8px 0 8px 40px;
  border-radius: 50px;
  display: grid;
  justify-items: start;
  grid-template-columns: repeat(2, 1fr);
  row-gap: 4px;
  color: var(--secondary-color);
  background-color: #fff;
}

.features-table {
  width: 100%;
  padding: 8px 0;
  border-radius: 10px;
  display: grid;
  justify-items: start;
  grid-template-columns: repeat(2, 1fr);
  row-gap: 4px;
  color: var(--secondary-color);
  background-color: #fff;
}

.bold {
  font-weight: 600;
}

.feature {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 8px;
  font-size: 0.85rem;
}

.feature-cell {
  width: 100%;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 8px;
  font-size: 0.85rem;
  border-bottom: 1px solid #e6e6e6;
  padding: 8px 0px 8px 16px;
}
.cell-input {
  height: 40px;
  width: 100%;
  padding: 5px 15px;
  font-size: 0.85rem;
  border: 1px solid #ced6e0;
  margin-right: 8px;
}

select.cell-select {
  width: 100%;
  height: 35px;
  border-radius: 5px;
  box-shadow: none;
  border: 1px solid #ced6e0;
  transition: all 0.3s ease-in-out;
  font-size: 0.85rem;
  padding: 5px 15px;
  background: none;
  color: #1a3b5d;
  margin-right: 8px;
  font-family: 'Source Sans Pro', sans-serif;
}

.feature span:first-child {
  font-size: 1.1rem;
}

.feature-cell span:first-child {
  font-size: 1.1rem;
}

.about-container {
  width: 100%;
}

.about {
  margin-bottom: 10px;
  font-size: 1.1rem;
  font-weight: 500;
  text-transform: uppercase;
}

.description {
  font-size: 0.9rem;
  color: var(--text-color);
}

.reserve-container {
  display: flex;
  flex-direction: column;
  width: 100%;
  gap: 12px;
  padding: 0 8px 12px 8px;
}

.reserve-btn {
  width: 100%;
  padding: 20px 0;
  border: none;
  font-size: 1rem;
  font-weight: 600;
  text-transform: uppercase;
  color: #fff;
  border-radius: 10px;
  background-color: var(--primary-color);
}

@media screen and (min-width: 768px) {
  .details {
    width: 80%;
    padding-bottom: 0;
  }

  .reserve-container {
    flex-direction: row;
    gap: 8px;
  }

  .reserve-btn {
    width: fit-content;
    padding: 15px 35px;
    margin-bottom: 30px;
  }

  .reserve-btn:hover {
    cursor: pointer;
    box-shadow: 2.5px 2.5px 0 0 var(--primary-hover);
    transform: translate(-2.5px, -2.5px);
  }

  .img-container {
    width: 100%;
  }
}

@media screen and (min-width: 992px) {
  .back-btn {
    top: 14px;
    font-size: 2rem;
  }

  .detail-container {
    flex-direction: row;
  }

  .detail-wrapper {
    width: 40%;
  }

  .details {
    width: 100%;
    align-items: start;
    margin-top: 2rem;
  }

  .banner {
    margin-bottom: 60px;
  }

  .banner-text {
    display: block;
    position: absolute;
    top: 50%;
    left: 4%;
    transform: translateY(-50%);
    color: #fff;
    font-size: 1rem;
    text-transform: capitalize;
  }

  .banner-text p:first-child {
    font-size: 1.7rem;
    font-weight: 500;
    text-transform: uppercase;
  }

  .img-container {
    width: 500px;
  }

  .features {
    grid-template-columns: repeat(4, 1fr);
    justify-items: center;
    padding-left: 0;
  }

  .features-table {
    border-radius: 10px;
    grid-template-columns: repeat(2, 1fr);
  }

  .feature {
    font-size: 0.95rem;
  }

  .feature-cell {
    font-size: 0.95rem;
  }

  .feature span:first-child {
    font-size: 1.25rem;
  }

  .reserve-container {
    padding-top: 12px;
  }
}

@media screen and (min-width: 1200px) {
  .title {
    font-size: 1.3rem;
    padding: 18px 0;
  }

  .img-container {
    width: 600px;
  }

  .back-btn {
    left: 15px;
    top: 13px;
    font-size: 2.2rem;
  }

  .details {
    /*width: 70%;*/
  }

  .banner-text {
    left: 6%;
    font-size: 1.1rem;
  }

  .rating {
    font-size: 1.2rem;
  }

  .price p {
    font-size: 1rem;
  }

  .banner-text p:first-child {
    font-size: 2rem;
  }

  .price p:first-child {
    font-size: 1.5rem;
  }

  .about {
    font-size: 1.15rem;
  }

  .description {
    font-size: 1rem;
  }
}
