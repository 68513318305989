.add_car_container {
  display: flex;
  flex-direction: column;
}

.add_car_wrapper {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
}

.car_form_container {
  width: 90%;
  padding: 0 17px;
  margin-top: 20px;
  margin-bottom: 50px;
}

.add_car_button {
  padding: 6px 12px;
}

.add_car_form_wrapper {
  display: flex;
  padding: 1.5rem;
  flex-direction: column;
  gap: 1rem;
  border-radius: 5px;
  background-color: #fff;
}

.first_wrapper {
  display: flex;
  flex-direction: column;
  gap: 1rem;
}

.second_wrapper {
  display: flex;
  justify-content: space-between;
}

.second_wrapper label {
  width: 50%;
}

.second_wrapper input {
  width: 55px;
  text-align: center;
}

.add_car_form_wrapper label {
  display: flex;
  flex-direction: column;
  gap: 3px;
  font-size: 0.9rem;
  font-weight: 500;
  text-transform: uppercase;
  color: var(--secondary-color);
}

.add_car_form_wrapper input {
  padding: 8px 12px;
  font-size: 0.9rem;
  outline: none;
  border: none;
  border-bottom: 1px solid var(--primary-color);
  margin-top: 0.5rem;
  color: var(--text-color);
}

.third_wrapper {
  display: flex;
  justify-content: flex-start;
  gap: 10px;
  align-items: center;
}

.add_car_form_wrapper textarea {
  padding: 8px 12px;
  outline: none;
  border: none;
  border-bottom: 1px solid var(--primary-color);
  margin-top: 0.5rem;
  color: var(--text-color);
}

.car_form_container h2 {
  text-align: center;
  color: var(--primary-color);
  padding-bottom: 2rem;
}

.button_wrapper {
  display: flex;
  justify-content: center;
}

.button_wrapper .btn {
  width: 100px;
  height: 40px;
  padding: 0;
  text-align: center;
}

.cancel_btn {
  font-size: 0.9rem;
  font-weight: 600;
  width: 100px;
  height: 40px;
  text-transform: uppercase;
  margin: 20px auto;
  color: var(--primary-color);
  border: 1px solid var(--primary-color);
  background-color: #fff;
}

.cancel_btn:hover {
  cursor: pointer;
  box-shadow: 2.5px 2.5px 0 0 var(--primary-hover);
  transform: translate(-2.5px, -2.5px);
}

@media screen and (min-width: 768px) {
  .car_form_container {
    width: 50%;
  }
}

@media screen and (min-width: 992px) {
  .button_wrapper .btn {
    width: 130px;
  }

  .cancel_btn {
    width: 130px;
  }
}
