.custom-form {
  border-radius: 10px;
  padding: 2rem;
  padding-top: 0;
}

.formTitleWrapper {
  width: 100%;
  display: flex;
  justify-content: center;
  padding: 1rem 0;
  padding-top: 0;
}

.formGroup {
  margin-bottom: 20px;
  display: flex;
  gap: 8px;
}

.formImageGroup {
  width: 100%;
  display: flex;
  gap: 3rem;
}

.previewImageWrapper {
  position: relative;
  width: 100px;
  height: 100px;
}

.previewImage {
  width: 100%;
  height: 100%;
}

select.basic {
  width: 100%;
  height: 50px;
  border-radius: 5px;
  box-shadow: none;
  border: 1px solid #ced6e0;
  transition: all 0.3s ease-in-out;
  font-size: 18px;
  padding: 5px 15px;
  background: none;
  color: #1a3b5d;
  margin-top: 8px;
  font-family: 'Source Sans Pro', sans-serif;
}

.iconWrapper {
  padding: 0.1rem;
  position: absolute;
  right: -15px;
  top: -10px;
  background-color: #fff;
  border: 1px solid #555;
  border-radius: 99px;
  width: 1.5rem;
  height: 1.5rem;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}

.label {
  font-size: 18px;
  font-weight: 600;
  color: #1a3b5d;
  width: 100%;
  display: block;
  user-select: none;
}

.input,
.textarea {
  width: 100%;
  border-radius: 5px;
  box-shadow: none;
  border: 1px solid #ced6e0;
  transition: all 0.3s ease-in-out;
  font-size: 18px;
  padding: 5px 15px;
  background: none;
  color: #1a3b5d;
  font-family: 'Source Sans Pro', sans-serif;
  margin-top: 8px;
}

.input-radio-label {
  display: flex;
  margin: 10px 15px;
}

.input-radio {
  box-shadow: 0px 0px 0px 1px #6d6d6d;
  font-size: 3em;
  width: 16px;
  height: 16px;
  margin-right: 7px;
  border: 4px solid #fff;
  background-clip: border-box;
  border-radius: 50%;
  appearance: none;
  transition:
    background-color 0.3s,
    box-shadow 0.3s;
}

.input-radio.on:checked {
  box-shadow: 0px 0px 0px 4px #DC143C;
  background-color: #DC143C;
}
.input-radio.off:checked {
  box-shadow: 0px 0px 0px 4px #DC143C;
  background-color: #DC143C;
}
.input-radio-wrapper {
  display: flex;
}

.input:hover,
.input:focus,
.textarea:hover,
.textarea:focus {
  border-color: #2962ff;
}

.input:focus,
.textarea:focus {
  box-shadow: 0px 10px 20px -13px rgba(32, 56, 117, 0.35);
}

.input {
  height: 50px;
}

.textarea {
  resize: vertical;
  min-height: 80px;
}

.textButton {
  padding: 0.5rem 1rem;
  background-color: #fff;
  border: none;
  cursor: pointer;
  margin-bottom: 1rem;
  margin-top: 1rem;
}

.textButton span {
  font-size: 1.1rem;
  color: #2962ff;
  font-weight: 600;
  text-decoration: underline;
  font-style: italic;
}

.buttonsWrapper {
  display: flex;
  align-items: center;
  margin-top: 3rem;
  justify-content: center;
}

.cardSubmitButton {
  width: 100%;
  height: 55px;
  background: #2962ff;
  border: none;
  border-radius: 10px;
  font-size: 1.3rem;
  font-weight: 500;
  font-family: 'Source Sans Pro', sans-serif;
  box-shadow: 3px 10px 20px 0px rgba(35, 100, 210, 0.3);
  color: #fff;
  cursor: pointer;
}

.cardSubmitButton:disabled {
  background: rgb(175, 175, 175);
  cursor: default;
}

.cardSecondaryButton {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  max-width: 300px;
  height: 55px;
  padding: 10px;
  background-color: var(--primary-color);
  border: none;
  cursor: pointer;
  margin-right: 0.5rem;
  border-radius: 10px;
}

.cardSecondaryButton:disabled {
  cursor: default;
  background: rgb(175, 175, 175);
}

.cardSecondaryButton span {
  font-size: 1.3rem;
  font-weight: 600;
  color: #fff;
}

.cardSecondaryButton:disabled span {
  color: #fff;
}

.uploadLabel {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  max-width: 300px;
  height: 55px;
  padding: 10px;
  border-radius: 10px;
  background-color: #fff;
  border: 1px solid #2364d2;
  cursor: pointer;
}

.fileInput {
  display: none;
}

.uploadText {
  color: #555;
}

@media screen and (max-width: 480px) {
  .custom-form {
    padding: 25px;
  }
  .cardButton {
    margin-top: 10px;
  }
}

@media screen and (max-width: 360px) {
  .custom-form {
    padding: 15px;
  }
}

@media screen and (max-width: 576px) {
  .cardFormContainer {
    margin: 0 auto;
  }
}
