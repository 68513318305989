.container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  margin-top: 3rem;
}

.LoaderPageContainer {
  height: 100vh;
}

.wrapper {
  width: 90%;
}

.loaderWrapper {
  display: flex;
  justify-content: center;
}

.content-table {
  border-collapse: collapse;
  margin: 25px 0;
  font-size: 0.9em;
  width: 100%;
  border-radius: 5px 5px 0 0;
  overflow: hidden;
  box-shadow: 0 0 20px rgba(0, 0, 0, 0.15);
}

.content-table thead tr {
  background-color: var(--primary-color);
  color: #ffffff;
  text-align: left;
  font-weight: bold;
}

.content-table th,
.content-table td {
  padding: 16px 28px;
}

.content-table tbody tr {
  border-bottom: 1px solid #dddddd;
}

.content-table tbody tr:nth-of-type(even) {
  background-color: #f3f3f3;
}

.content-table tbody tr:last-of-type {
  border-bottom: 2px solid var(--primary-color);
}

.content-table tbody tr.active-row {
  font-weight: bold;
  color: var(--primary-color);
}

.title {
  font-size: 2rem;
  font-weight: 600;
  color: #383838;
}

.titleWrapper {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding-bottom: 2rem;
}

.createButtonWrapper {
  display: flex;
  width: 100%;
}

.button {
  padding: 0 1.5rem;
  height: 50px;
  background: var(--primary-color);
  border: none;
  border-radius: 10px;
  font-size: 1.1rem;
  font-weight: 500;
  font-family: 'Source Sans Pro', sans-serif;
  box-shadow: 3px 10px 20px 0px rgba(35, 100, 210, 0.3);
  color: #fff;
  cursor: pointer;
}

.button.danger {
  background: #ff5b29;
}

.iconsWrapper {
  display: flex;
  gap: 1rem;
}

.iconButton {
  background-color: #fff;
  padding: 0.5rem;
  border: none;
  border-radius: 0.5rem;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  box-shadow: 0 0 20px rgba(0, 0, 0, 0.15);
  transition: all 0.5s ease;
}

.deleteButton:hover {
  background-color: hsl(0, 95%, 65%);
}

.sendButton:hover {
  background-color: #3ea6ff;
}

.alertTextWrapper {
  text-align: center;
  margin-bottom: 3rem;
}

.alertTextWrapper p {
  font-size: 1.5rem;
}

.alertButtonsWrapper {
  display: flex;
  justify-content: center;
  gap: 1rem;
  padding-bottom: 1rem;
}

.imageWrapper {
  max-height: 100px;
  height: 100px;
  max-width: 150px;
}
