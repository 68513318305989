.modalWrapper {
  background-color: rgba(0, 0, 0, 0.3);
  backdrop-filter: blur(4px);
  position: fixed;
  padding: 0 16px;
  bottom: 0;
  top: 0;
  left: 0;
  right: 0;
  z-index: 300;
}

.modalBodyWrapper {
  display: flex;
  align-items: center;
  width: 100%;
  height: 100%;
}

.modalBody {
  background: #fff;
  border-radius: 1rem;
  padding: 1rem;
  display: flex;
  width: 100%;
  flex-direction: column;
  position: relative;
  max-width: 700px;
  max-height: 80vh;
  margin: 10vh auto;
  overflow-y: auto;
}

.modalHeader {
  display: flex;
  justify-content: flex-end;
  align-items: center;
}

.iconButton {
  background-color: #fff;
  border: none;
  cursor: pointer;
}
